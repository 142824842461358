import PageMenuInterface from '@lib/interfaces/page-menu.interface'
import PagePathConstant from '@lib/constants/page-path.constant'
import PagePathInterface from '@lib/interfaces/page-path.interface'
import MyAccountIcon from '@inline-img/general/nav-bar/my-account-icon'
import BillingIcon from '@inline-img/general/nav-bar/billing-icon'
import ContactUsIcon from '@inline-img/general/nav-bar/contact-us-icon'
import SettingsIcon from '@inline-img/left-side-menu/settings-icon'
import NotificationNavIcon from '@inline-img/general/nav-bar/notification-nav-icon'

export const RIGHT_SUBMENU = (): Array<PagePathInterface> => [
  {
    name: 'Account and Settings',
    path: PagePathConstant.ACCOUNT_AND_SETTINGS,
  },
  {
    name: 'Contact Us',
    path: PagePathConstant.CONTACT_US,
  },
]

export const DEMO_RIGHT_SUBMENU = (): Array<PagePathInterface> => [
  {
    name: 'Account and Settings',
    path: PagePathConstant.ACCOUNT_AND_SETTINGS,
  },
  {
    name: 'Contact Us',
    path: PagePathConstant.CONTACT_US,
  },
]

// ____________________________________________________________________________________
// TOP NAVIGATION BAR MENU

export const USER_MENU = (): Array<PagePathInterface> => [
  {
    name: 'My account',
    path: PagePathConstant.ACCOUNT_AND_SETTINGS,
    icon: MyAccountIcon(),
    class: 'jsAccountAndSettings',
  },
  {
    name: 'Contact us',
    path: PagePathConstant.CONTACT_US,
    icon: ContactUsIcon(),
  },
]

export const USER_DEMO_MENU = (): Array<PagePathInterface> => [
  {
    name: 'My account',
    path: PagePathConstant.ACCOUNT_AND_SETTINGS,
    icon: MyAccountIcon(),
  },
  {
    name: 'Billing',
    path: PagePathConstant.BILLING,
    icon: BillingIcon(),
  },
  {
    name: 'Contact us',
    path: PagePathConstant.CONTACT_US,
    icon: ContactUsIcon(),
  },
]

export const SETTING_MENU = (): Array<PagePathInterface> => [
  {
    name: 'Tenant Settings',
    path: PagePathConstant.SETTINGS,
    icon: SettingsIcon(),
  },
  {
    name: 'Alerts',
    path: PagePathConstant.NOTIFICATION,
    icon: NotificationNavIcon(),
  },
]

export const NO_CLOUD_CONNECTORS_MAIN_MENU = (): Array<PageMenuInterface> => [
  {
    page: {
      name: 'Onboarding',
      path: PagePathConstant.ONBOARDING,
    },
    subPages: [],
  },
]

export const MAIN_MENU = (): Array<PageMenuInterface> => [
  {
    page: {
      name: 'Onboarding',
      path: PagePathConstant.ONBOARDING,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Dashboard',
      path: PagePathConstant.DASHBOARD,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Sources',
      path: PagePathConstant.SOURCES,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Policies',
      path: PagePathConstant.POLICIES,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Assets',
      path: '',
    },
    subPages: [
      {
        name: 'EC2',
        path: PagePathConstant.EC2_INVENTORY_TABLE,
      },
      {
        name: 'EBS',
        path: PagePathConstant.EBS_INVENTORY_TABLE,
      },
      {
        name: 'S3',
        path: PagePathConstant.S3_BUCKET_INVENTORY_TABLE,
      },
      {
        name: 'EFS',
        path: PagePathConstant.EFS_INVENTORY_TABLE,
      },
      {
        name: 'Servers',
        path: PagePathConstant.OVA_SERVER_INVENTORY_TABLE,
      },
      {
        name: 'Generic Host',
        path: PagePathConstant.GENERIC_HOSTS_INVENTORY_TABLE,
      },
    ],
  },
  {
    page: {
      name: 'Jobs',
      path: '',
    },
    subPages: [
      {
        name: 'All',
        path: PagePathConstant.JOBS,
        hideInSubMenu: true,
      },
      {
        name: 'Cloud Connector',
        path: PagePathConstant.CLOUD_CONNECTOR,
      },
      {
        name: 'Deployment',
        path: PagePathConstant.DEPLOYMENT,
      },
      {
        name: 'System',
        path: PagePathConstant.SYSTEM,
      },
      {
        name: 'Filters',
        path: PagePathConstant.FILTERS,
        hideInSubMenu: true,
      },
    ],
  },
  {
    page: {
      name: 'Reports',
      path: PagePathConstant.REPORTS,
    },
    subPages: [],
  },
]

export const MAIN_DEMO_MENU = (): Array<PageMenuInterface> => [
  {
    page: {
      name: 'Onboarding',
      path: PagePathConstant.ONBOARDING,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Dashboard',
      path: PagePathConstant.DASHBOARD,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Sources',
      path: PagePathConstant.SOURCES,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Policies',
      path: PagePathConstant.POLICIES,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Assets',
      path: '',
    },
    subPages: [
      {
        name: 'EC2',
        path: PagePathConstant.EC2_INVENTORY_TABLE,
      },
      {
        name: 'EBS',
        path: PagePathConstant.EBS_INVENTORY_TABLE,
      },
      {
        name: 'S3',
        path: PagePathConstant.S3_BUCKET_INVENTORY_TABLE,
      },
      {
        name: 'EFS',
        path: PagePathConstant.EFS_INVENTORY_TABLE,
      },
      {
        name: 'Servers',
        path: PagePathConstant.OVA_SERVER_INVENTORY_TABLE,
      },
      {
        name: 'Generic Host',
        path: PagePathConstant.GENERIC_HOSTS_INVENTORY_TABLE,
      },
    ],
  },
  {
    page: {
      name: 'Jobs',
      path: '',
    },
    subPages: [
      {
        name: 'All',
        path: PagePathConstant.JOBS,
        hideInSubMenu: true,
      },
      {
        name: 'Cloud Connector',
        path: PagePathConstant.CLOUD_CONNECTOR,
      },
      {
        name: 'Deployment',
        path: PagePathConstant.DEPLOYMENT,
      },
      {
        name: 'System',
        path: PagePathConstant.SYSTEM,
      },
      {
        name: 'Filters',
        path: PagePathConstant.FILTERS,
        hideInSubMenu: true,
      },
    ],
  },
  {
    page: {
      name: 'Reports',
      path: PagePathConstant.REPORTS,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Audit Log',
      path: PagePathConstant.AUDIT_LOG,
    },
    subPages: [],
  },
]
